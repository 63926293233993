<template>
    <div>
        <div class="row">

            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        Question
                                    </th>
                                    <th>
                                        Answer
                                    </th>
                                    <th>
                                        Comments
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in checklist" :key="'q-' + item.id">
                                    <td>
                                        {{ item.question }}
                                    </td>
                                    <td>
                                        <i v-if="item.answer == 1" class="bx bx-check text-success"></i>
                                        <i v-else-if="item.answer == 0 || item.answer == null"
                                            class="bx bx-x text-danger"></i>
                                    </td>
                                    <td>{{ item.comments || "No Comment." }}</td>

                                </tr>
                                <!-- <tr>
                                <td colspan="2" class="text-center">
                                    <button class="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#addQuestionModal">Add Question</button>
                                </td>
                            </tr> -->
                            </tbody>
                        </table>

                        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 mt-2">

                            <div v-for="image in images" :key="'image-' + image.id" class="col">
                                <div class="card">
                                    <secure-image @click="showImage(image)" class="card-img-top" alt="..."
                                        :id="'imagepic-' + image.id"
                                        :endpoint="`/v1/checklist/vehicle/images/${image.id}`"></secure-image>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SecureImage from '../../../components/SecureImage.vue';

export default {
    name: 'vehicle.inspection',
    props: ['id'],
    components: {
        SecureImage,
    },
    data() {
        return {
            checklist: [],
            images: []
        }
    },
    mounted() {
        this.loadChecklist()
    },
    methods: {
        loadChecklist() {
            axios.get(`${process.env.VUE_APP_API_URL}/v1/checklist/vehicle/response/${this.id}`)
                .then(response => {
                    this.checklist = response.data.answers;
                    this.images = response.data.images;
                })
                .catch(error => {
                    this.$error("Failed to load checklist.", error);
                })
        },
    }
}
</script>